<template>
  <v-container fluid>
    <TableViewComponent
      title="Table intitulés postes"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un intitulé poste"
      itemLabel="intitulé poste"
      itemsLabel="intitulés postes"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet intitulé poste ?"
      :loading="loading"
      :vcols="{ xl: '8', lg: '10', md: '11', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    >

    <template v-slot:beforetable>
      <v-card-text>
        <div class="d-flex">
          <v-select
            v-model="selectedSource"
            :items="typeSource"
            return-object
            placeholder="Sélectionner une source métier"
            class="mx-4 my-0 pa-0"
            @change="onSelectedSource()"
          />
        </div>
      </v-card-text>
    </template>
    
    </TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { RomeHightDomainsService } from "@/service/dictionary/rome_hight_domains_service.js";
import { RomeJobsService } from "@/service/dictionary/rome_jobs_service.js";
import { InternalJobsService } from "@/service/dictionary/internal_jobs_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


const SOURCE_TYPE_ALL = "Tous les métiers";
const SOURCE_TYPE_OFFICIAL = "Les métiers ROME";
const SOURCE_TYPE_INTERNE = "Les métiers internes";

export default {
  name: "TableJobsTitle",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],
      internalJobs: [],
      officialJobs: [],

      /**le classe d'accès au service */
      serviceInternalJobs: null,
      serviceOfficialJobs: null,
      serviceRomeHightDomains: null,

      // Source sélectionnée pour les donnée affichées
      selectedSource: null,
      // Liste des sources possibles
      typeSource: [
        SOURCE_TYPE_ALL,
        SOURCE_TYPE_OFFICIAL,
        SOURCE_TYPE_INTERNE,
      ],
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.jobTitle.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];
        this.internalJobs = [];
        this.officialJobs = [];

        // Récupération des métiers officiels
        let officialJobs = await this.serviceOfficialJobs.getAll();
        
        // Récupération des métiers interne
        let internalJobs = await this.serviceInternalJobs.getAll();

        // Récupération des grands domaines rome
        let romeHightDomains = await this.serviceRomeHightDomains.getAll();        

        // Chargement des métiers interne et officiels
        await this.loadInternalJobs(internalJobs, romeHightDomains);
        await this.loadOfficialJobs(officialJobs, romeHightDomains);

        // Concaténation des métiers officiels et internes
        this.entities = this.internalJobs.concat(this.officialJobs);      

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    // Configuration des métiers internes
    async loadInternalJobs(entities, romeHightDomains) {
      // Traitement sur les jobs interne
      entities.forEach((entity) => {
        // Récupération du label du grand domaine rome
        let hightDomain = romeHightDomains.find((d) => d.code == entity.romeHightDomainCode);

        entity.labelHightDomain = "-";
        if (hightDomain) {
          entity.labelHightDomain = hightDomain.label;
        }

        entity.label = '-';
        entity.code = '-';
        // On spécifie que c'est un métier non interne
        entity.internalJob = true;
      
        // Définition des routes pour les icones
        entity.view = () => {
          this.$router.push(
            routes.jobTitle.view.root 
            + "/" 
            + entity.id
            + "?internal="
            + entity.internalJob
          );
        };

        // Pour les métier ROME officiels, les modifications et la suppression n'est pas autorisée
        if (entity.internalJob) {
          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.jobTitle.edit.root + "/" + entity.id
            );
          }
          // action de suppression
          entity.delete = async () => {
            try {
              await this.serviceInternalJobs.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'intitulé poste : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };
        }

        this.internalJobs.push(entity);     
      });
    },


    // Configuration des métiers officiels
    async loadOfficialJobs(entities, romeHightDomains) {
      // Traitement sur les jobs officiels
      entities.forEach((entity) => {
        // Récupération du label du grand domaine rome
        let hightDomain = romeHightDomains.find((d) => d.code == entity.romeHightDomainCode);

        entity.labelHightDomain = "-";
        if (hightDomain) {
          entity.labelHightDomain = hightDomain.label;
        }
        // On spécifie que c'est un métier non interne
        entity.internalJob = false;

        entity.view = () => {
          this.$router.push(
            routes.jobTitle.view.root 
            + "/" 
            + entity.code
            + "?internal="
            + entity.internalJob
          );
        };
        
        // Désactive les boutons de modification et de suppression
        entity.disabledEditAction = true;
        entity.disableDeleteAction = true;

        this.officialJobs.push(entity);
      });
    },

    
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Métier ROME",
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: "Code ROME",
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: "Grand domaine ROME",
          align: "start",
          sortable: true,
          width: "200px",
          value: "labelHightDomain",
        },
        {
          text: "Alias ROME",
          align: "start",
          sortable: true,
          value: "alias",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },


    /** Evènement de sélection de la source à présenter */
    onSelectedSource() {
      this.entities = [];
      
      if (this.selectedSource == null || this.selectedSource == SOURCE_TYPE_ALL) {
        this.entities = this.internalJobs.concat(this.officialJobs);
      }
      else if (this.selectedSource == SOURCE_TYPE_OFFICIAL) {
        this.entities = this.officialJobs;
      }
      else if (this.selectedSource == SOURCE_TYPE_INTERNE) {
        this.entities = this.internalJobs;
      }
    }
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditDictionary, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadDictionary];
    },
  },
  mounted() {
    // Instanciation des services
    this.serviceRomeHightDomains = new RomeHightDomainsService(this.$api.getRomeHightDomainsApi());
    this.serviceInternalJobs = new InternalJobsService(this.$api.getInternalJobsApi());
    this.serviceOfficialJobs = new RomeJobsService(this.$api.getRomeJobsApi());

    this.load();
  },
};
</script>

<style>
</style>