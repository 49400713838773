import * as statusMapper from "@/service/error_request_mapper.js";

export class RomeJobsService {
  constructor(api) {
    this.api = api;
  }

  async getAll() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async getById(entityId) {
    return this.api
      .getById(entityId)

      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(entityId);
        converter.convert(error);
      });
  }

}
